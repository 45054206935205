<template>
	<div class="padding-top-10 edit-contain">
		<top :bgWhite='true'></top>

		<div class="contents  padding-bottom-20 ">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/fictionDetail',query:{aid:aid} }">电子书</el-breadcrumb-item>
				<el-breadcrumb-item>版权申诉</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="bg-white margin-top-20 padding-lr-50">
				<div class="  padding-top-20 fs24  padding-bottom-15">
					版权申诉
				</div>
				<div class="list-items flex margin-bottom point">
					<div class="book-cover margin-right-sm" v-if="info.thumb">
						<img :src="info.thumb | filterArray">
					</div>
					<div class="right-info flex flex-direction  justify-around">
						<p class="book-title">{{info.title}}</p>
						<div class="flex align-center writer-info" v-if="info.images">
							<img :src="user.head_img" class="block-44 radius-44 margin-right-xs">
							<div class="left-info">
								<p class="name">{{user.user_nickname}}</p>
								<div class="writer-tag flex align-center margin-top-5" v-if="info.user && info.user.tags_info && info.user.tags_info.length>0 && info.user.tags_info[0]!=''">
									<p  v-if="j<2" size="mini" class="margin-right-5 tag-item" v-for="(ele,j) in info.user.tags_info" :key="j">{{ele}}</p>
								</div>
							</div>
						</div>
						<div class="flex align-center">
							<img src="../../assets/image/icon/push.png" class="block-18 margin-right-10">
							<p class="fs12">发布时间：{{info.create_time}}</p>
						</div>
						<el-row :gutter="24">
							<el-col :span="8">
								<div class="point flex align-center" @click="toCollect(info)" >
									<img src="../../assets/image/icon/view.png" class="block-16 margin-right-5"  key="uncollect">
									<span class="fs14 text-999" >{{info.read_num || 0}}</span>
								</div>
							</el-col>
							<el-col :span="8">
								<div class="grid-content bg-purple">
									<div class="fs12 flex align-center">
										<img src="../../assets/image/home/like.png" class="block-16 margin-right-5">
										<span class="fs14 text-999">{{info.like_num || 0}}</span>
									</div>
								</div>
							</el-col>
							<el-col :span="8">
								<div class="point flex align-center" @click="giveLike(item)">
									<img src="../../assets/image/home/message.png" class="block-16 margin-right-5"  >
									<span class="fs14 text-999" >{{info.comm_num || 0}}</span>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
				<el-form  label-position="top"  label-width="80px">
					<el-form-item  label="版权类型" required class="fs26" label-width="50px">
					    <el-select v-model="copyright_type" placeholder="请选择版权类型">
					      <el-option :label="item.name" :value="item.aid" v-for="(item,index) in complainList" :key="index"></el-option>
					    </el-select>
					 </el-form-item>
					 <el-form-item  label="材料证明" required>
					     <el-upload
					       class="margin-top-30"
					       :action="BaseUrl+'/api/v1/5fc615f7da92b'"
					       list-type="picture-card"
						   :limit="6"
					       :on-preview="handlePictureCardPreview"
					       :on-remove="handleRemove"
					       :on-change="handleChange"
					       :file-list="fileList">
					       <i class="el-icon-plus"></i>
					     </el-upload>
					     <el-dialog :visible.sync="dialogVisible">
					     	<img width="100%" :src="dialogImageUrl" alt="">
					     </el-dialog>
						 <p class="text-999 fs12">请上传作品登记书或其他证明图片，最多上传6张</p>
					  </el-form-item>
					  <el-form-item  label="相关备注"  class="fs26" >
					      <el-input type="textarea" placeholder="最多输入300字（选填）" maxlength="300" show-word-limit rows="6" v-model="remark" style="width: 50%;"></el-input>
					   </el-form-item>
					   <el-form-item  label="请留下您的联系方式" required class="fs26" >
					       <el-input v-model="contact" placeholder="手机号/邮箱/QQ号" style="width: 50%;"></el-input>
					    </el-form-item>
				</el-form>
				<div class=" margin-top-70 margin-bottom-20 padding-bottom-60 flex align-center justify-center">
					<span class="bg-e54f no-border radius-100 padding-lr-60  padding-tb-10 point text-white"
						@click="submitForm()"> 提交</span>
				</div>
			</div>

		

		</div>
		<bottom :bgWhite='true'></bottom>
	</div>
</template>

<script>
	import top from '@/components/top'
	import bottom from '@/components/bottom'
	export default {
		name: 'editBook',
		components: {
			bottom,
			top
		},
		data() {
			return {
				dialogImageUrl: '',//大图链接
				fileList:[],//图片列表
				dialogVisible: false,
				BaseUrl: this.imgUrl,
				info:{},//电子书详情
				user:{}, //作者详情
				labelPosition:'top',
				aid:'', //电子书id
				content: '', //富文本内容
				editorOption: {},
				contact:'', //联系方式
				remark:'', //备注
				prove_materials:[], //证明材料
				copyright_type:'', //版权id
				complainList:[], //投诉类型
				form: {
					title: '', //标题
					content: '', //文章内容
					fileList: [],
					is_my: true,
					is_pay: true, //是否付费
					money: '', //阅读费用
					is_free: true, //是否免费封面
					code:'',//兑换码
				}
			}
			// editorOption里是放图片上传配置参数用的，例如：
			// action:  '/api/product/richtext_img_upload.do',  // 必填参数 图片上传地址
			// methods: 'post',  // 必填参数 图片上传方式
			// token: '',  // 可选参数 如果需要token验证，假设你的token有存放在sessionStorage
			// name: 'upload_file',  // 必填参数 文件的参数名
			// size: 500,  // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
			// accept: 'multipart/form-data, image/png, image/gif, image/jpeg, image/bmp, image/x-icon,image/jpg'  // 可选 可上传的图片格式
		},
		filters:{
			filterArray(val){
				return val[0]
			}
		},
		mounted() {
			this.aid = this.$route.query.aid;
			this.getBookDetail();
			this.getComplain()//获取投诉类型
		},
		methods: {
			// 删除
			handleRemove(file,fileList) {
				this.fileList = fileList;
			},
			// 大图预览
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handleChange(file,fileList){
				this.fileList = fileList;
			},
			// 提交申诉
			submitForm() {
				if(this.fileList.length==0){
					this.$message.error('请先上传证明材料');
					return;
				};
				if(!this.contact){
					this.$message.error('请留下您的联系方式');
					return;
				};
				let prove_materials = new Array;
				prove_materials = this.fileList.map(item=>{
					return item.response.data.data.fileId
				});
				let imgurl = '';
				if(prove_materials.length>0){
					 imgurl = prove_materials.join(',')
				}
				this.ajax('post', '/v1/6184d93295886', {
					document_aid: this.aid, //申诉文章aid
					remark: this.remark, //相关备注
					prove_materials: imgurl, //证明材料
					contact: this.contact,
					copyright_type: this.copyright_type //版权类型aid
				}, res => {
					if (res.code == 1) {
						this.$message.success('提交成功');
						setTimeout(()=> {
							this.$router.go(-1)
						}, 800);
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 获取电子书详情
			getBookDetail() {
				this.ajax('post', '/v1/613afc809f5e3', {
					article_id: this.aid
				}, res => {
					if (res.code == 1) {
						this.user = res.data.user;
						this.info = res.data
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 获取投诉类型
			getComplain() {
				this.ajax('post', '/v1/6184da721b8e8', {}, res => {
					if (res.code == 1) {
						this.complainList = res.data;
						this.copyright_type = res.data[0].aid
					} else {
						this.$message.error(res.msg);
					}
				})
			},
		}
	}
</script>

<style scoped>
	.edit-contain {
		width: 100vw;
		height: 100vh;
		background-color: #F8F8F8;
		overflow: auto;
	}

	.contents {
		width: 80%;
		height: auto;
		margin: 80px auto;
	}

	.myQuillEditor {
		height: 400px;
	}

	/deep/.el-form-item__label {
		font-size: 24px;
		font-weight: 500;
		color: #333333;
	}

	/deep/.el-radio__inner {
		width: 20px;
		height: 20px;
	}
	.code_input{
		border-radius: 4px 0 0 4px;
	}
	.confirm-btn{
		border-radius: 0px 4px 4px 0px;
	}
	/deep/.el-radio__input.is-checked .el-radio__inner {
		border-color: #E54F42;
		background: #E54F42;
	}

	/deep/.el-radio__input.is-checked+.el-radio__label {
		color: #333333;
	}
	.book-cover {
		width: 104px;
		height: 146px;
		background: rgba(0, 0, 0, 0);
		opacity: 1;
		border-radius: 8px 0px 0px 8px;
		
	}
	img {
		width: 100%;
		height: 100%;
		border-radius: 8px 0px 0px 8px;
	}
	.right-info {
		height: 146px;
	}
	
	.book-title {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		opacity: 1;
	}
	.left-info {
		
	}
	.name {
		font-size: 11px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #333333;
		opacity: 1;
	}
	.writer-tag {
		
	}
	.tag-item{
		width: 66px;
		height: 20px;
		text-align: center;
		opacity: 1;
		font-size: 8px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 20px;
		color: #FFFFFF;
		opacity: 1;
	}
	.tag-item:nth-child(1){
		background: url(../../assets/image/icon/label-bg1.png) no-repeat;
		background-size: cover;
	}
	.tag-item:nth-child(2){
		background: url(../../assets/image/icon/label-bg2.png) no-repeat;
		background-size: cover;
	}
</style>
